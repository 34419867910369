@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Zen+Tokyo+Zoo&display=swap');

.nav{
    height: 6vh;
    width: 100%;
    background-color: #313442;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: 'Zen Tokyo Zoo', cursive;
    font-size: 35px;
}
.home{
    box-sizing: border-box;
    height: 94vh;
    width: 100%;
    gap: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.avatar{
    height: 50%;
    width: 50%;
    max-width: 400px;
    max-height: 400px;
    margin-top: 40px;
    margin-bottom: 45px;
}
.btns{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
button{
    width: 6.5em;
    height: 2.5em;
    margin: 10px;
    font-size: 20px;
    font-weight: 600;
    font-family: 'Roboto Mono', monospace;
    background-color: rgb(231, 231, 231);
    box-shadow: 2px 3px 5px rgb(102, 101, 101);
    border-radius: 15px;
    border: none;
    transition: 0.2s;
}
button:active{
    box-shadow: none;
}
.btns > button:hover{
    background-color: #ffffff;
}
#gen{
    background-color: #4361ee;
    color: white;
}
#down{
    background-color: #EB3349;
    color: white;
}
